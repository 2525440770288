import React, { useEffect } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { IoAirplane, IoCalendarClearOutline, IoHome } from "react-icons/io5";


export const IndexPage: React.FC<{}> = ({ }) => {
  return (
    <div>
      <h1>Index!</h1>
    </div>
  )
}

export const CalendarPage: React.FC<{}> = ({ }) => {
  return (
    <div className='flex w-full'>
      <iframe src="https://calendar.google.com/calendar/embed?src=smaccoun%40hakomipractice.com&ctz=America%2FDenver" style={{ border: 0 }} width="800" height="600" frameBorder="0" scrolling="no"></iframe>
    </div>
  )
}