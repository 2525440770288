import { Resp } from "@contactlab/appy";
import { pipe } from "fp-ts/lib/function";
import React, { useCallback, useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { Auth } from "../../Auth";
import { PracticeGroupTile, ProposePracticeGroupForm } from "../../Components/Groups.Components";
import { Loading } from "../../Loading";
import { A, E, O, RD } from "../../Prelude";
import { AllPracticeGroupsResponse, Api } from "../../Services/Backend/Api";
import { Backend } from "../../Services/Backend/Backend";
import { OnboardFormContainer } from "./OnboardFormContainer";

type SignupForm = {
  email: string
}

export const OnboardSignupPage: React.FC<{}> = ({ }) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<SignupForm>();
  const navigate = useNavigate()

  const navToNextScreen = useCallback(() => {
    navigate('/onboard/profile')
  }, [])

  function onSubmit(data: FieldValues) {
    console.log("DATA ! ", data)

    const email = data.email
    const password = 'hakomi'


    Auth.createUserWithEmailAndPassword({ email, password })().then(er => {
      console.log("CREATE USER WITH EMAIL PWD RESULT: ", er)
      if (E.isRight(er)) {
        Backend.post('users', { email, password, firebaseUserId: er.right.user.uid })().then(er => {
          console.log("RESULT OF POSTING USER! ", er)
          navToNextScreen()
        })
      } else {
        switch (er.left.code) {
          case 'auth/email-already-in-use':
            console.log("ALREADY IN USE! ")
            Auth.signInUserWithEmailAndPassword({ email, password })().then(er => {
              console.log("RESULT OF SIGNING IN USER WITH EMAIL AND PASSWORD! ", er)
              if (E.isRight(er)) {
                navigate('/dashboard')
              }
            })
            break
          default:
            console.log("ER! ", er.left)
        }
      }
    })

  }

  return (
    <>
      <OnboardFormContainer onSubmit={handleSubmit(onSubmit)}>
        <h3 className="text-white">Sign up or log in</h3>
        <div className="form-control w-full max-w-xs mt-8">
          <label className="label">
            <span className="label-text font-bold">Email</span>
          </label>
          <input
            type="email" placeholder=""
            {...register('email', { required: true })}
            className="input input-bordered w-full max-w-xs"
          />
        </div>
        {errors.email && <span>This field is required</span>}
        <div className='mt-16'>
          <input type='submit' className='btn btn-accent' value='Sign Up' />
        </div>
        <div style={{ height: 2, width: 500 }}></div>
      </OnboardFormContainer>
    </>
  )
}

export const OnboardPreselectPracticeGroups: React.FC<{}> = ({ }) => {
  const [page, setPage] = useState<'Existing' | 'Propose'>('Existing')
  const navigate = useNavigate()

  switch (page) {
    case 'Existing':
      return (
        <div className="w-screen h-screen">
          <OnboardJoinExistingPracticeGroupsPage onNext={() => { setPage('Propose') }} />
        </div>
      )
    case 'Propose':
      return (
        <OnboardProposePracticeGroupPage />
      )
  }
}


const OnboardProposePracticeGroupPage: React.FC<{}> = ({ }) => {
  const [isModal, setIsModal] = useState(false)
  const navigate = useNavigate()

  return (
    <div className='md:flex w-screen h-screen min-w-0'>

      <Modal isOpen={isModal}>
        <div className="flex justify-end">
          <button className='btn bg-white' onClick={() => { setIsModal(false) }}>X</button>
        </div>
        <ProposePracticeGroupForm onSubmitDone={() => {
          navigate('/dashboard/practiceGroups')
        }} />
      </Modal>
      <div className='flex w-full h-full flex-col bg-base-200 wrap md:w-5/12 md:min-w-0 p-8 prose justify-between'>
        <div>
          <h2 className='mt-16'>Would you like to propose a new practice group?</h2>
          <h4 className='mt-16'>(you can do this again later)</h4>
        </div>
        <div className='w-full flex flex-col justify-end space-y-2'>
          <div className="md:hidden btn text-black w-full" onClick={() => {
            setIsModal(true)
          }}>
            Propose
          </div>
          <div
            onClick={() => {
              navigate('/dashboard/practiceGroups')
            }}
            className='w-full md:w-10/12 btn  text-black'
          >
            Not Now
          </div>
        </div>
      </div>
      <div className='invisible md:visible flex flex-col grow-0 justify-center items-start shrink min-w-0 p-8'>
        <button className="btn btn-info bg-info-content w-64 h-32 text-black" onClick={() => {
          setIsModal(true)
        }}>
          Propose
        </button>
      </div>
    </div>

  )
}

const OnboardJoinExistingPracticeGroupsPage: React.FC<{ onNext: () => void }> = ({ onNext }) => {
  const [myGroups, setMyGroups] = useState<RD.RemoteData<any, Resp<AllPracticeGroupsResponse>>>(RD.pending)
  const navigate = useNavigate()

  useEffect(() => {
    Api.PracticeGroup.getMyPracticeGroups().then(er => {
      setMyGroups(RD.fromEither(er))
    })
  }, [])

  return pipe(
    myGroups,
    RD.toNullable,
    O.fromNullable,
    O.fold(
      () => (
        <Loading.FullScreenPage />
      ),
      (mg) => (
        <div className='flex flex-1 h-screen min-w-0'>
          <div className='flex w-full flex-col bg-base-200 wrap md:w-5/12 md:min-w-0 p-8 prose justify-between'>
            <h2 className='mt-16'>Would you like to join any existing practice groups?</h2>
            <div className="md:hidden">
              <div className='w-full md:hidden'>
                <PracticeGroupsSelectionList mg={mg.data} />
              </div>
            </div>
            <div className='w-full flex justify-end'>
              <div onClick={() => {
                onNext()
              }} className='w-full md:w-10/12 btn'>
                Next
              </div>
            </div>
          </div>
          <div className='invisible md:visible flex grow-0 items-start shrink min-w-0 pt-32'>
            <PracticeGroupsSelectionList mg={mg.data} />
          </div>
        </div>
      )
    )
  )

}


const PracticeGroupsSelectionList: React.FC<{ mg: AllPracticeGroupsResponse }> = ({ mg }) => {
  const [localJoins, setLocalJoins] = useState<string[]>([])
  return (
    <div className='flex w-full items-start space-x-4 overflow-x-auto p-4'>
      {mg.allPracticeGroups.length === 0
        ?
        (
          <div className='flex justify-center items-center h-64'>
            <h5 className='prose'>You have joined all practice groups</h5>
          </div>
        )
        :
        (
          mg.allPracticeGroups.map(pg => {
            return (
              <PracticeGroupTile
                practiceGroupWithUsers={pg}
                action={
                  localJoins.find(lj => lj === pg.practiceGroup.id) === undefined
                    ?
                    (
                      {
                        tag: 'Join', onJoin: (pgid) => {
                          setLocalJoins(lj => [...lj, pgid])
                          Api.PracticeGroup.joinPracticeGroup(pgid)().then(er => {
                          })
                        }
                      }
                    )
                    :
                    ({
                      tag: 'Leave', onUnJoin: (pgid) => {
                        setLocalJoins(lj => pipe(
                          lj,
                          A.deleteAt(lj.findIndex(l => l === pgid)),
                          O.toNullable
                        )!)
                        Api.PracticeGroup.leavePracticeGroup(pgid)().then(er => {
                          // setMyGroups(RD.fromEither(er))
                        })
                      }
                    })
                }
              />
            )
          })
        )
      }
    </div>
  )
}