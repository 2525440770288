import React, { useEffect } from "react";
import { IoCalendarClearOutline, IoPersonCircle } from "react-icons/io5";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Auth } from "../../Auth";
import hakomiFlower from '../../assets/hakomi-flower.png'

export const DashboardLayout: React.FC<{}> = ({ }) => {
  const navigate = useNavigate()

  return (
    <div className='drawer drawer-mobile'>
      <input id="drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <nav className="navbar w-full">
          <div className="flex flex-1 justify-between md:gap-1 lg:gap-2">
            <label htmlFor="drawer" className="btn btn-square btn-ghost drawer-button lg:hidden">
              <svg
                width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                className="inline-block h-5 w-5 stroke-current md:h-6 md:w-6"><path
                  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"
                >
                </path>
              </svg>
            </label>
          </div>
          <div onClick={() => {
            navigate('profile')
          }} className='flex flex-initial items-start justify-end'>
            <IoPersonCircle size={64} />
          </div>
        </nav>
        <div className='flex flex-col flex-1 h-full p-8'>
          <Outlet />
        </div>
      </div>


      <div className='drawer-side'>
        <label htmlFor="drawer" className="drawer-overlay"></label>
        <aside className="bg-base-200 w-80 pt-8 flex flex-col">
          <a className="flex-0 flex justify-center items-center w-full px-2 py-4">

            <img src={hakomiFlower} style={{ width: 40, height: 40, borderRadius: 40 }} />
            <h1 className="font-title font-bold text-lg md:text-3xl inline-flex ml-4">Hakomi Practice</h1>
          </a>
          <ul className="menu menu-compact flex flex-col flex-1 p-0 px-4 mt-8">
            <div className='flex-1'>
              <MenuLink to='practiceGroups' title="Practice Groups" />
              {/* <MenuLink to='feed' title="Feed" /> */}
              <MenuLink to='calendar' title="Calendar" />

              <MenuLink to='videoCall' title="Video Call" />


              <MenuLink to='/liveSessions/steve' title="Join" />
            </div>

            <button className="text-lg font-light mt-8 mb-8" onClick={() => {
              Auth.signOut().then()
            }} >
              Sign Out
            </button>

          </ul>
        </aside>
      </div>


    </div >
  )
}

const MenuLink: React.FC<{ to: string, title: string }> = ({ to, title }) => {
  return (
    <li className='mt-8'>
      <NavLink to={to} className={({ isActive, isPending }) => (
        isActive
          ? "active text-black"
          : isPending
            ? "pending"
            : "text-black opacity-20"
      )}>
        <span className="flex items-center">
          <IoCalendarClearOutline size={32} />
          <span className="text-lg ml-4">{title}</span>
        </span>
      </NavLink>
    </li>
  )
}

export const IndexPage: React.FC<{}> = ({ }) => {
  return (
    <div>
      <h1>Index!</h1>
    </div>
  )
}

export const CalendarPage: React.FC<{}> = ({ }) => {
  return (
    <div className='flex w-full'>
      <iframe src="https://calendar.google.com/calendar/embed?src=smaccoun%40hakomipractice.com&ctz=America%2FDenver" style={{ border: 0 }} width="800" height="600" frameBorder="0" scrolling="no"></iframe>
    </div>
  )
}

