import { FirebaseOptions, initializeApp } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyD9h8oa0XCN9IScLUmaqHb1As2tUhr_YxY",
  authDomain: "production-da46f.firebaseapp.com",
  databaseURL: "https://production-da46f-default-rtdb.firebaseio.com",
  projectId: "production-da46f",
  storageBucket: "production-da46f.appspot.com",
  messagingSenderId: "928432401742",
  appId: "1:928432401742:web:be7b01b05f5c0dee94021f",
  measurementId: "G-RN4DH4N7NG"
};

const devFirebaseConfig = {
  apiKey: "AIzaSyAInK64GnQBa0JT1uhURM1hkCv2s3FDJI8",
  authDomain: "development-791fd.firebaseapp.com",
  projectId: "development-791fd",
  storageBucket: "development-791fd.appspot.com",
  messagingSenderId: "189394923760",
  appId: "1:189394923760:web:f8fd64287fdcc913601235",
  measurementId: "G-KR9VRT1QRE"
};


// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);