import * as Rx from 'rxjs'
import * as RxO from 'rxjs/operators'
import * as O from 'fp-ts/option'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import * as T from 'fp-ts/Task'
import * as TE from 'fp-ts/TaskEither'
import * as RD from '@devexperts/remote-data-ts'
import * as iots from 'io-ts'
import * as React from 'react'
import { pipe } from 'fp-ts/lib/function'


function useTaskEitherEffect<E, V>(te: TE.TaskEither<E, V>) {
  const [v, setV] = React.useState<RD.RemoteData<E, V>>(RD.pending)

  React.useEffect(() => {
    te().then(er => {
      setV(RD.fromEither(er))
    })
  }, [])

  return v
}

const rdToOption = <E, V>(rd: RD.RemoteData<E, V>) => pipe(rd, RD.toNullable, O.fromNullable)

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export {
  O, E, RD, T, TE, A,
  Rx, RxO,
  iots,
  useTaskEitherEffect,
  rdToOption
}