import { Resp } from '@contactlab/appy';
import { sequenceT } from 'fp-ts/lib/Apply';
import { pipe } from "fp-ts/lib/function";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import { PracticeGroupTile, ProposePracticeGroupForm } from "../../Components/Groups.Components";
import { Loading } from "../../Loading";
import { O, RD, useTaskEitherEffect } from "../../Prelude";
import { Api, AllPracticeGroupsResponse } from "../../Services/Backend/Api";

const customStyles = {
  padding: '30px',
  zincIndex: 1000,
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0
  },
};

export const PracticeGroupsPage: React.FC<{}> = ({ }) => {
  const [myGroups, setMyGroups] = useState<RD.RemoteData<any, Resp<AllPracticeGroupsResponse>>>(RD.initial)
  const me = useTaskEitherEffect(Api.Me.getMe)
  const [isCreatePGModal, setIsPGModal] = useState(false)

  useEffect(() => {
    setMyGroups(RD.pending)
    Api.PracticeGroup.getMyPracticeGroups().then(er => {
      console.log("ER! ", er)
      setMyGroups(RD.fromEither(er))
    })
  }, [])

  return pipe(
    sequenceT(RD.remoteData)(me, myGroups),
    RD.fold3(
      () => (<Loading.FullPage />),
      (err) => (<div>{`${JSON.stringify(err)}`}</div>),
      ([me, pgs]) => (

        <div className='pb-8'>
          <div className='flex flex-col'>
            <Modal style={customStyles} isOpen={isCreatePGModal}>
              <div className="flex flex-col bg-secondary p-16">
                <div className="flex justify-end">
                  <button className='btn bg-white' onClick={() => { setIsPGModal(false) }}>X</button>
                </div>
                <ProposePracticeGroupForm onSubmitDone={() => {
                  setIsPGModal(false)
                  Api.PracticeGroup.getMyPracticeGroups().then(er => {
                    setMyGroups(RD.fromEither(er))
                  })
                }} />
              </div>
            </Modal>
            <h1 className="text-2xl mb-4">My Practice Groups</h1>
            <div className="w-11/12 overflow-x-auto py-4">
              <div className='flex space-x-8'>
                {pgs.data.myPracticeGroups.map((pg, idx) => (
                  <div key={idx} >
                    <PracticeGroupTile
                      practiceGroupWithUsers={pg}
                      action={{
                        tag: 'Leave', onUnJoin: (pgid) => {
                          setMyGroups(RD.pending)
                          setTimeout(() => {
                            Api.PracticeGroup.leavePracticeGroup(pgid)().then(er => {
                              setMyGroups(RD.fromEither(er))
                            })
                          }, 500);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start mt-12 w-full">
            <div className="flex grow shrink-0">
              <h1 className="text-2xl mb-16">All Practice Groups</h1>
              <button className="btn btn-accent ml-8" onClick={() => { setIsPGModal(true) }}>+ Propose practice group</button>
            </div>
            <div className="flex w-11/12 overflow-x-auto py-4">
              <div className='flex flex-1 space-x-4'>
                {pgs.data.allPracticeGroups.map((pg, idx) => (
                  <div key={idx}>
                    <PracticeGroupTile
                      practiceGroupWithUsers={pg}
                      action={{
                        tag: 'Join', onJoin: (pgid) => {
                          setMyGroups(RD.pending)
                          setTimeout(() => {
                            Api.PracticeGroup.joinPracticeGroup(pgid)().then(er => {
                              setMyGroups(RD.fromEither(er))
                            })
                          }, 500);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start mt-12 w-full">
            <div className="flex grow shrink-0">
              <h1 className="text-2xl mb-16">Proposed Practice Groups</h1>
              {/* <button className="btn btn-accent ml-8" onClick={() => { setIsPGModal(true) }}>+ Propose practice group</button> */}
            </div>
            <div className="flex w-11/12 overflow-x-auto py-4">
              <div className='flex flex-1 space-x-4'>
                {pgs.data.proposedPracticeGroups.map((pg, idx) => (
                  <div key={idx}>
                    <PracticeGroupTile
                      practiceGroupWithUsers={{ ...pg, users: pg.interestedUsers }}
                      action={{
                        tag: 'Interested', myId: me.data.id, onInterested: (pgid) => {
                          setMyGroups(RD.pending)
                          setTimeout(() => {
                            Api.PracticeGroup.interestedInPracticeGroup(pgid)().then(er => {
                              setMyGroups(RD.fromEither(er))
                            })
                          }, 500);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )
    ),
  )
}