import { useEffect } from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import { Auth } from "../Auth";
import { globalRouter } from "../GlobalRouter";
import { O, RD } from "../Prelude";
import { DashboardLayout } from "./Dashboard/Dashboard";
import { PracticeGroupsPage } from "./Dashboard/PracticeGroupsPage";
import { ProfilePage } from "./Dashboard/ProfilePage";
import { VideoCallPage } from "./Dashboard/VideoCallPage";
import { LiveSessionPage } from "./LiveSessionPage";
import { OnboardPageContainer } from "./Onboard/OnboardFormContainer";
import { OnboardProfilePage } from "./Onboard/OnboardProfile.Page";
import { OnboardSignupPage, OnboardPreselectPracticeGroups } from "./Onboard/Signup.Page";
import { CalendarPage, IndexPage } from "./Root";

const RootPage: React.FC<{}> = ({ }) => {
  const navigate = useNavigate()

  useEffect(() => {
    console.log("MOUNTING APP!")
    Auth.authUser$.subscribe(rdAuthedUser => {
      console.log("RD AUTHED USER! ", rdAuthedUser)
      if (RD.isSuccess(rdAuthedUser)) {
        const mbAuthedUser = rdAuthedUser.value
        if (O.isSome(mbAuthedUser)) {
          navigate('/dashboard/calendar')
        }
      }
    })
  }, [])

  return (
    <div>
      Loading...
    </div>
  )
}

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />
  },

  {
    path: "/onboard",
    element: <OnboardPageContainer />,
    children: [
      { index: true, element: <OnboardSignupPage /> },
      { path: 'profile', element: <OnboardProfilePage /> },
    ]
  },
  {
    path: "/onboard/join_existing_groups",
    element: <OnboardPreselectPracticeGroups />,
  },
  {
    path: "/liveSessions/:id",
    element: <LiveSessionPage />,
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true, element: <IndexPage />
      },
      // {
      //   path: 'feed',
      //   element: <FeedPage />
      // },
      {
        path: 'calendar',
        element: <CalendarPage />
      },
      {
        path: 'practiceGroups',
        element: <PracticeGroupsPage />
      },
      {
        path: 'videoCall',
        element: <VideoCallPage />
      },
      {
        path: 'profile',
        element: <ProfilePage />
      }
    ]
  },
]);

globalRouter.next(appRouter)