import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Api, CreatePracticeGroupDTO, locationTypeDescription, PracticeGroupData, PracticeGroupWithUsers } from "../Services/Backend/Api";
import computersInSpace from '../assets/computers-in-space.png'
import hakomiFlower from '../assets/hakomi-flower.png'

type Action =
  { tag: 'Join', onJoin: (practiceGroupId: string) => void }
  | { tag: 'Leave', onUnJoin: (practiceGroupId: string) => void }
  | { tag: 'Interested', myId: string, onInterested: (practiceGroupId: string) => void }

export const PracticeGroupTile: React.FC<{ practiceGroupWithUsers: PracticeGroupWithUsers, action: Action }> = ({ practiceGroupWithUsers, action }) => {
  const { practiceGroup, users } = practiceGroupWithUsers
  const { location, id, locationType, dayOfWeek, startTime } = practiceGroup
  return (
    <div className="max-w-none m-0 p-0">
      <div className="card card-compact w-64 bg-neutral-content shadow-xl">
        <div
          className='relative w-full rounded-lg justify-center h-64 bg-contain'
        // style={{ backgroundImage: `url(${practiceGroup.location_type === 'remote' ? computersInSpace : hakomiFlower})` }}
        >
          <div
            className='absolute inset-0 opacity-50 rounded-lg bg-contain'
            style={{ backgroundImage: `url(${practiceGroup.locationType === 'remote' ? computersInSpace : hakomiFlower})` }}
          />
          <div
            className='absolute inset-0 z-10 flex flex-col rounded-lg p-2'
          >
            <h2 className='text-white text-2xl font-bold'>{`${dayOfWeek}`}</h2>
            <h2 className='text-white text-2xl font-bold'>{`${startTime} (MST)`}</h2>
          </div>
          <div
            className='absolute bottom-0 right-0 z-10 flex flex-col rounded-lg p-2'
          >
            {action.tag === 'Interested'
              ?
              (
                <p className='text-white'>{`${users ? `${users.length} interested` : ``}`}</p>
              )
              :
              (
                <p className='text-white'>{`${users ? `${users.length} members` : ``}`}</p>

              )
            }
            <p className='text-white'>{`(${locationTypeDescription(locationType)})`}</p>
          </div>
        </div>
        <div className="card-body">
          {/* <h2 className="card-title">{`${day_of_week} ${location_type} Meet Up`}</h2> */}
          {/* <p>{`Every ${day_of_week} at ${start_time}`}</p> */}
          {action.tag === 'Leave'
            ?
            (
              <div className="badge badge-success">Joined</div>
            )
            :
            (<></>)
          }
          <div className="card-actions justify-end">
            {action.tag === 'Interested' && users && users.find(u => u.id === action.myId)
              ?
              (<></>)
              :
              (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    switch (action.tag) {
                      case 'Join':
                        action.onJoin(id)
                        return
                      case 'Leave':
                        action.onUnJoin(id)
                        return
                      case 'Interested':
                        action.onInterested(id)
                        return
                    }
                  }}
                >
                  {action.tag}
                </button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

type CreatePracticeGroupFormInputs = {
  location_type: 'in_person' | 'remote'
  day_of_week: string
  hour: number
  minute: number
  location: string
}

export const ProposePracticeGroupForm: React.FC<{ onSubmitDone: () => void }> = ({ onSubmitDone }) => {
  const { register, handleSubmit } = useForm<CreatePracticeGroupFormInputs>()
  const onSubmit: SubmitHandler<CreatePracticeGroupFormInputs> = (data) => {
    const { location_type, day_of_week, hour, minute, location } = data
    const dto: CreatePracticeGroupDTO = {
      location_type,
      day_of_week,
      start_time: `${hour}:${minute}:00`,
      location
    }

    Api.PracticeGroup.createPracticeGroup(dto, true)().then(er => {
      console.log("RESULT OF CREATING PRACITCE GROUP! ", er)
      onSubmitDone()
    })
  }

  return (
    <div className='flex flex-col shrink grow-0 items-start max-w-none'>
      <h1 className="text-lg text-white font-bold">Propose a practice group</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="form-control flex flex-col grow-0 shrink items-start space-y-8">
        <div>
          <label className="label">
            <span className="label-text font-bold">Location Type</span>
          </label>
          <select className="select select-bordered" {...register("location_type")}>
            <option value="remote">Remote</option>
            <option value="in_person">In Person</option>
          </select>
        </div>

        <div>
          <label className="label">
            <span className="label-text font-bold">Day of Week</span>
          </label>
          <select className="select select-bordered" {...register('day_of_week')}>
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
              <option value={day}>{day}</option>
            ))}
          </select>
        </div>

        <div className='flex flex-col shrink grow-0'>
          <label className="label">
            <span className="label-text font-bold">Start Time (Please specify in MST!)</span>
          </label>
          <span className="flex items-center shrink grow-0">
            <select className="select" {...register('hour')}>
              {Array.from({ length: 24 }).map((_, h) =>
                <option value={String(h)}>{String(h).padStart(1, '0')}</option>
              )}
            </select>
            <p className="text-white text-lg font-bold ml-2 mr-2">{` : `}</p>
            <select className="select" {...register('minute')}>
              {Array.from({ length: 60 }).map((_, h) =>
                <option value={String(h)}>{String(h).padStart(1, '0')}</option>
              )}
            </select>
          </span>
        </div>

        <div>
          <label className="label">
            <span className="label-text font-bold">Location (optional)</span>
          </label>
          <input className='input input-bordered w-64' type='text' {...register('location')} placeholder="" />
        </div>

        <div className="flex w-full justify-end" >
          <input className='btn w-full' type='submit' value="Submit" />
        </div>
      </form>
    </div>
  )
}