import { pipe } from "fp-ts/lib/function";
import { O, Rx, TE } from "./Prelude";
import { Api, LiveSessionsCreationResponse } from "./Services/Backend/Api";
import { Backend } from "./Services/Backend/Backend";
import AgoraRtc from "agora-rtc-sdk-ng";
import { IAgoraRTCClient } from 'agora-rtc-sdk-ng'
import { IAgoraRTCRemoteUser, ICameraVideoTrack } from "agora-rtc-sdk-ng"


export module Agora {

  export const engine = AgoraRtc.createClient({ mode: 'live', codec: 'vp8' });

  export const joinedUsers$ = new Rx.BehaviorSubject<O.Option<IAgoraRTCRemoteUser[]>>(O.none)

  // engine.on('user-joined', (user: IAgoraRTCRemoteUser, mediaType?: 'video' | 'audio') => {
  //   console.log("USER JOINED", user, mediaType)
  //   const users = pipe(
  //     joinedUsers$.value,
  //     O.map(users => [...users, user]),
  //     O.getOrElse(() => [user])
  //   )
  //   joinedUsers$.next(O.some(users))
  // })

  async function startLivestream(config: LiveSessionsCreationResponse, isHost: boolean) {
    await engine.setClientRole('host')

    const { appId, uid, channelToken, channelName } = config
    console.log("JOINING CHANNEL", uid, channelName)
    await engine.join(appId, channelName, channelToken, uid)

    console.log("ENGINE UID AFTER JOINING! ", engine.uid)

    const localAudioTrack = await AgoraRtc.createMicrophoneAudioTrack();
    const localVideoTrack = await AgoraRtc.createCameraVideoTrack();

    return localVideoTrack
  }

  export function startLiveSession() {
    return pipe(
      Api.LiveSessions.createLiveSession,
      TE.mapLeft(e => `Error creating live session: ${JSON.stringify(e)}`),
      TE.chain(r => {
        console.log("RESULT OF CREATING LIVE SESSION", r)
        return TE.tryCatch(
          () => startLivestream(r.data, true),
          (e) => `Error starting livestream: ${e}`
        )
      })
    )
  }
}