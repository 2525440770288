import { pipe } from "fp-ts/lib/function"
import { E, iots, T, TE } from "../../Prelude"
import * as Http from '@contactlab/appy'
import { withHeaders } from '@contactlab/appy/combinators/headers'
import { withDecoder, Decoder, toDecoder } from '@contactlab/appy/combinators/decoder'
import { withBody } from '@contactlab/appy/combinators/body';
import { failure } from 'io-ts/PathReporter'
import { Auth } from "../../Auth"
import { kMaxLength } from "buffer"

export const fromIots = <A>(d: iots.Decoder<unknown, A>): Decoder<A> =>
  toDecoder(d.decode, e => new Error(failure(e).join('\n')));

export module Backend {

  const remoteBaseUrl = 'https://dolphin-app-nrdyn.ondigitalocean.app'
  const baseUrl = remoteBaseUrl

  // const localBaseUrl = 'http://localhost:3000'
  // const baseUrl = localBaseUrl

  export function get<V>(endpoint: string, decoderV: iots.Decoder<unknown, V>): TE.TaskEither<Http.Err, Http.Resp<V>> {
    const url = `${baseUrl}/${endpoint}`
    const d = fromIots(decoderV)
    const req = pipe(Http.get, withDecoder(d))(url)
    return pipe(
      req,
      T.chainFirst(er => {
        return T.fromIO(() => {
          console.log("ER", er)
          if (E.isLeft(er) && er.left.type === 'ResponseError' && er.left.response.status === 401) {
            Auth.signOut().then()
          }
        })
      })
    )
  }

  export function post(endpoint: string, body: any): TE.TaskEither<Http.Err, Http.Resp<any>> {
    const url = `${baseUrl}/${endpoint}`
    return pipe(
      Http.post,
      withHeaders({ 'Content-Type': 'application/json' }),
      withBody(body)
    )(url)
  }

  export function patch(endpoint: string, body: any): TE.TaskEither<Http.Err, Http.Resp<any>> {
    const url = `${baseUrl}/${endpoint}`
    return pipe(
      Http.patch,
      withHeaders({ 'Content-Type': 'application/json' }),
      withBody(body)
    )(url)
  }

  const jwtError = (reqInit: Http.RequestInfoInit): Http.Err => ({ type: 'RequestError', error: new Error('Could not get JWT'), input: reqInit })

  export function authGet<V>(endpoint: string, decoderV: iots.Decoder<unknown, V>): TE.TaskEither<Http.Err, Http.Resp<V>> {
    return pipe(
      TE.fromTask(Auth.getJWT),
      TE.mapLeft(_ => jwtError(['', { method: 'GET' }])),
      TE.chain(jwt => {
        return _authGet(endpoint, jwt, decoderV)
      })
    )
  }

  function _authGet<V>(endpoint: string, jwt: string, decoderV: iots.Decoder<unknown, V>): TE.TaskEither<Http.Err, Http.Resp<V>> {
    const url = `${baseUrl}/${endpoint}`
    const d = fromIots(decoderV)
    const req = pipe(
      Http.get,
      withHeaders({ Authorization: `Bearer ${jwt}` }),
      withDecoder(d)
    )(url)

    return pipe(
      req,
      TE.orElseFirstIOK((err) => {
        return () => {
          if (err.type === 'ResponseError' && err.response.status === 401) {
            Auth.signOut().then()
            console.log("UNAUTHORIZED!")
          }
        }
      })
    )
  }


  export function authPost<V>(endpoint: string, body: any, decoderV: iots.Decoder<unknown, V>): TE.TaskEither<Http.Err, Http.Resp<V>> {
    return pipe(
      TE.fromTask(Auth.getJWT),
      TE.mapLeft(_ => jwtError(['', { method: 'POST' }])),
      TE.chain(jwt => {
        return _authPost(endpoint, jwt, body, decoderV)
      })
    )
  }


  export function authPatch<V>(endpoint: string, body: any, decoderV: iots.Decoder<unknown, V>): TE.TaskEither<Http.Err, Http.Resp<V>> {
    return pipe(
      TE.fromTask(Auth.getJWT),
      TE.mapLeft(_ => jwtError(['', { method: 'POST' }])),
      TE.chain(jwt => {
        return _authPatch(endpoint, jwt, body, decoderV)
      })
    )
  }

  function _authPost<V>(endpoint: string, jwt: string, body: any, decoderV: iots.Decoder<unknown, V>): TE.TaskEither<Http.Err, Http.Resp<V>> {
    const url = `${baseUrl}/${endpoint}`
    return pipe(
      Http.post,
      withHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }),
      withBody(body),
      withDecoder(fromIots(decoderV))
    )(url)
  }

  function _authPatch<V>(endpoint: string, jwt: string, body: any, decoderV: iots.Decoder<unknown, V>): TE.TaskEither<Http.Err, Http.Resp<V>> {
    const url = `${baseUrl}/${endpoint}`
    return pipe(
      Http.patch,
      withHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }),
      withBody(body),
      withDecoder(fromIots(decoderV))
    )(url)
  }
}
