import * as FbAuth from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from "./Firebase";
import { globalRouter } from "./GlobalRouter";
import { O, RD, Rx, RxO, TE } from "./Prelude";

const auth = FbAuth.getAuth(firebaseApp);


export module Auth {
  export function createUserWithEmailAndPassword(p: { email: string, password: string }): TE.TaskEither<FbAuth.AuthError, FbAuth.UserCredential> {
    return TE.tryCatch(
      () => FbAuth.createUserWithEmailAndPassword(auth, p.email, p.password),
      (e) => e as FbAuth.AuthError
    )
  }

  export function signInUserWithEmailAndPassword(p: { email: string, password: string }): TE.TaskEither<FbAuth.AuthError, FbAuth.UserCredential> {
    return TE.tryCatch(
      () => FbAuth.signInWithEmailAndPassword(auth, p.email, p.password),
      (e) => e as FbAuth.AuthError
    )
  }

  export function signOut() {
    return FbAuth.signOut(auth)
  }

  const authState$ = new Rx.BehaviorSubject<RD.RemoteData<any, O.Option<FbAuth.User>>>(RD.pending)

  export const authUser$ = authState$.pipe(
    RxO.distinctUntilChanged((prev, cur) => {
      if (RD.isPending(prev) && RD.isPending(cur)) {
        return true
      } else {
        return false
      }
    })
  )

  authUser$.subscribe(au => {
    console.log("AUTH USER! ", au)

    if (RD.isSuccess(au)) {
      const mbAu = au.value
      if (O.isNone(mbAu)) {
        globalRouter.value?.navigate('/onboard')
      }
    }
  })

  setTimeout(() => {
    onAuthStateChanged(auth, user => {
      console.log("AUTH STATE CHANGE! ", user)
      const mbUser = O.fromNullable(user)
      authState$.next(RD.success(mbUser))
    })
  }, 2000);


  export async function getJWT() {
    const user = O.toNullable(RD.toNullable(authState$.value)!)!
    return FbAuth.getIdToken(user)
  }
}