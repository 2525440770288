import { TypeOf } from "io-ts";
import { iots } from "../../Prelude";
import { Backend } from "./Backend";

const locationTypeT = iots.keyof({ remote: undefined, 'in_person': undefined })
type LocationType = TypeOf<typeof locationTypeT>

export const UserDataT = iots.type({
  id: iots.string,
  email: iots.string,
  firstName: iots.union([iots.string, iots.null]),
  lastName: iots.union([iots.string, iots.null])
})

export type UserData = iots.TypeOf<typeof UserDataT>

const PracticeGroupDataT = iots.type({
  id: iots.string,
  locationType: locationTypeT,
  dayOfWeek: iots.string,
  startTime: iots.string,
  location: iots.string,
})

export type PracticeGroupData = iots.TypeOf<typeof PracticeGroupDataT>


const PracticeGroupWithUsersT = iots.type({
  practiceGroup: PracticeGroupDataT,
  users: iots.array(UserDataT)
})

export type PracticeGroupWithUsers = iots.TypeOf<typeof PracticeGroupWithUsersT>


const ProposedPracticeGroupT = iots.type({
  practiceGroup: PracticeGroupDataT,
  interestedUsers: iots.array(UserDataT)
})

type ProposedPracticeGroup = iots.TypeOf<typeof ProposedPracticeGroupT>


const AllPracticeGroupsResponseT = iots.type({
  allPracticeGroups: iots.array(PracticeGroupWithUsersT),
  myPracticeGroups: iots.array(PracticeGroupWithUsersT),
  proposedPracticeGroups: iots.array(ProposedPracticeGroupT)
})

export type AllPracticeGroupsResponse = iots.TypeOf<typeof AllPracticeGroupsResponseT>



const CreatePracticeGroupDTO = iots.type({
  location_type: iots.keyof({ remote: undefined, 'in_person': undefined }),
  day_of_week: iots.string,
  start_time: iots.string,
  location: iots.string
})

export type CreatePracticeGroupDTO = iots.TypeOf<typeof CreatePracticeGroupDTO>



export function locationTypeDescription(lt: LocationType): string {
  switch (lt) {
    case 'in_person':
      return 'In Person'
    case 'remote':
      return 'Remote'
  }
}



const PracticeGroupApi = {
  getMyPracticeGroups: Backend.authGet('practice_groups', AllPracticeGroupsResponseT.asDecoder()),

  joinPracticeGroup(practiceGroupId: string) {
    return Backend.authPost(`practice_groups/${practiceGroupId}/join`, {}, AllPracticeGroupsResponseT.asDecoder())
  },

  leavePracticeGroup(practiceGroupId: string) {
    return Backend.authPost(`practice_groups/${practiceGroupId}/leave`, {}, AllPracticeGroupsResponseT.asDecoder())
  },

  interestedInPracticeGroup(practiceGroupId: string) {
    return Backend.authPost(`practice_groups/${practiceGroupId}/interested`, {}, AllPracticeGroupsResponseT.asDecoder())
  },

  createPracticeGroup(dto: CreatePracticeGroupDTO, isProposal: boolean) {
    return Backend.authPost(`practice_groups?isProposal=${isProposal}`, dto, iots.unknown.asDecoder())
  },
}

const userProfile = iots.type({
  id: iots.string,
  name: iots.string,
  email: iots.string,
})

export type UserProfile = iots.TypeOf<typeof userProfile>

const MeApi = {
  getMe: Backend.authGet('profile', userProfile.asDecoder()),
  updateProfile: (body: { firstName: string, lastName: string }) => {
    return Backend.authPatch('profile', body, iots.unknown.asDecoder())
  }
}

const LiveSessionsCreationResponseT = iots.type({
  appId: iots.string,
  sessionId: iots.string,
  uid: iots.number,
  hostUserId: iots.string,
  channelToken: iots.string,
  channelName: iots.string,
})

export type LiveSessionsCreationResponse = iots.TypeOf<typeof LiveSessionsCreationResponseT>

const LiveSessionApi = {
  createLiveSession: Backend.authPost('live_sessions', {}, LiveSessionsCreationResponseT.asDecoder()),
  joinLiveSession: (sessionId: string) => Backend.authPost(`live_sessions/${sessionId}/join`, {}, LiveSessionsCreationResponseT.asDecoder()),
}

export const Api = {
  Me: MeApi,
  PracticeGroup: PracticeGroupApi,
  LiveSessions: LiveSessionApi
}