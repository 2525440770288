import { Resp } from '@contactlab/appy';
import AgoraUIKit from 'agora-react-uikit';
import { sequenceT } from 'fp-ts/lib/Apply';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { Loading } from '../../Loading';
import { E, O, RD, sleep, useTaskEitherEffect } from '../../Prelude';
import { Api, LiveSessionsCreationResponse } from '../../Services/Backend/Api';
import AgoraRTC from "agora-rtc-sdk-ng"
import { IAgoraRTCRemoteUser, ICameraVideoTrack } from "agora-rtc-sdk-ng"
import { Agora } from '../../Agora';
import { FirebaseOptions, initializeApp } from "firebase/app";
import * as rfb from 'firebase/database'
import { firebaseApp } from '../../Firebase';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object


// Initialize Realtime Database and get a reference to the service
const database = rfb.getDatabase(firebaseApp);

type MbRemoteData<E, V> = O.Option<RD.RemoteData<E, V>>

async function playAndPublish(videoTrack: ICameraVideoTrack) {
  videoTrack.play('localVideo')
  await sleep(3000)
  await Agora.engine.publish([videoTrack])
}

export const VideoCallPage: React.FC<{}> = ({ }) => {
  const rdMe = useTaskEitherEffect(Api.Me.getMe);
  const [videoCall, setVideoCall] = React.useState<MbRemoteData<any, ICameraVideoTrack>>(O.none);

  React.useEffect(() => {
  }, [])

  React.useEffect(() => {
    if (O.isSome(videoCall) && RD.isSuccess(videoCall.value)) {
      playAndPublish(videoCall.value.value).then()
    }
  }, [videoCall])

  React.useEffect(() => {
    if (O.isSome(videoCall) && RD.isSuccess(videoCall.value) && RD.isSuccess(rdMe)) {
      console.log("LISTENING TO USER JOINED!")
      const dbRef = rfb.ref(database, `liveSessions/steve/users`)
      rfb.onValue(dbRef, snapshot => {
        if (snapshot.exists()) {
          console.log("SNAPSHOT!", snapshot.val())
          console.log(snapshot.val());
          const allUsers: string[] = snapshot.val()
          const firstNotMe = allUsers.find(u => u !== rdMe.value.data.id)
          console.log("FIRST NOT ME! ", firstNotMe)
          if (firstNotMe !== undefined) {
            setTimeout(() => {
              const remoteUsers = Agora.engine.remoteUsers
              console.log("UID! ", Agora.engine.uid)
              console.log("REMOTE USERS! ", remoteUsers)
              if (remoteUsers.length > 0) {
                const firstRemote = remoteUsers[0]
                console.log("FIRST REMOTE! ", firstRemote.videoTrack)

                Agora.engine.subscribe(firstRemote, 'video').then(_ => {
                  firstRemote.videoTrack?.play("remoteVideo")
                }).catch(e => { console.log("FAILED TO SUBSCRIBE TO REMOTE USER! ", e) })
              }
            }, 1000);
          }
        } else {
          console.log("No data available");
        }
      })
      Agora.engine.on('user-published', async (user: IAgoraRTCRemoteUser, mediaType?: "audio" | "video") => {
        // Subscribe to the remote user when the SDK triggers the "user-joined" event.
        console.log("USER JOINED EVENT! ", user, mediaType)
        if (user.hasVideo) {
          await Agora.engine.subscribe(user, 'video');
          console.log("subscribe success");
          user.videoTrack?.play("remoteVideo");
        }
      })
    }
  }, [videoCall])

  return (
    <div className="w-screen h-screen">
      <div id='remoteVideo' style={{ width: '300px', height: '300px', border: '1px solid red' }} />
      {
        pipe(
          videoCall,
          O.fold(
            () => (
              <div style={{ display: "flex", width: "80vw", height: "80vh" }}>
                <div
                  className='btn'
                  onClick={() => {
                    Agora.startLiveSession()().then(er => {
                      console.log("RESULT OF STARTING LIVE SESSION! ", er)
                      setVideoCall(O.some(RD.fromEither(er)))

                    })
                  }}
                >
                  Start Video Call
                </div>
              </div>
            ),
            (rd) => {
              return pipe(
                sequenceT(RD.remoteData)(rdMe, rd),
                RD.toOption,
                O.map(([me, r]) => ({ liveSession: r, me })),
                O.fold(
                  () => (<Loading.FullPage />),
                  ({ liveSession, me }) => (
                    <div style={{ display: "flex", flexDirection: 'column', width: "80vw", height: "80vh" }}>
                      <div>{Agora.engine.uid}</div>
                      <div id='localVideo' style={{ width: '300px', height: '300px', border: '1px solid black' }} />


                      <div className='btn' onClick={() => {
                        liveSession.stop()
                        liveSession.close()
                        setVideoCall(O.none)
                      }} style={{ marginTop: '20px; width: 100px' }}>
                        Leave
                      </div>

                      {/* <AgoraUIKit
                  rtcProps={{
                    appId: '00ac06610b564ffb81b47e6a4e6002b1',
                    channel: r.channelName,
                    token: r.channelToken,
                    role: r.hostUserId === me.data.id ? 'host' : 'audience',
                  }}
                  callbacks={callbacks}
                /> */}
                    </div>
                  )
                )
              )
            }
          )
        )}
    </div>
  )
}