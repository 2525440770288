import { pipe } from 'fp-ts/lib/function';
import { useObservableEagerState } from 'observable-hooks';
import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Auth } from './Auth';
import { Loading } from './Loading';
import { O, RD } from './Prelude';
import { appRouter } from './Routes/Router';


export const App: React.FC<{}> = ({ }) => {
  const rdAuthedUser = useObservableEagerState(Auth.authUser$)

  return pipe(
    rdAuthedUser,
    RD.toNullable,
    O.fromNullable,
    O.fold(
      () => (
        <Loading.FullScreenPage />
      ),
      (mbUser) => (
        <div className='w-screen h-screen'>
          <RouterProvider router={appRouter} />
        </div>
      )
    )
  )
}

