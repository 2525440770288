import { pipe } from 'fp-ts/lib/function'
import * as React from 'react'
import { O, RD, rdToOption, useTaskEitherEffect } from '../../Prelude'
import { Api } from '../../Services/Backend/Api'

export const ProfilePage: React.FC<{}> = ({ }) => {
  const rdMe = useTaskEitherEffect(Api.Me.getMe)

  return pipe(
    rdMe,
    rdToOption,
    O.fold(
      () => <div>Loading...</div>,
      (me) => {
        return (
          <div>
            <div>{me.data.name}</div>
            <div>{me.data.email}</div>
          </div>
        )
      }
    )
  )
}