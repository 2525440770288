import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { motion } from "framer-motion";
import hakomiFlower from '../../assets/hakomi-flower.png';

export const OnboardPageContainer: React.FC<{}> = ({ }) => {
  return (
    <div className='flex flex-col justify-center items-center h-screen w-screen relative p-8 bg-primary' >
      <Outlet />
    </div>
  )
}

export const OnboardFormContainer: React.FC<{ children: React.ReactNode, onSubmit: () => void }> = ({ children, onSubmit }) => {
  return (
    <motion.form
      animate={{ x: 10 }}
      onSubmit={onSubmit}
      className='flex flex-col items-center justify-center w-5/6 shrink grow-0 p-8 prose max-w-none bg-secondary rounded-lg'
    >
      <img src={hakomiFlower} style={{ width: 100, height: 100, borderRadius: 100 }} />
      {children}
    </motion.form>
  )
}