import React, { useState } from "react"
import { useParams } from "react-router-dom"
import AgoraRTC from "agora-rtc-sdk-ng"
import { ICameraVideoTrack, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng'
import { Api, LiveSessionsCreationResponse } from "../Services/Backend/Api"
import { E, O, RD, sleep } from "../Prelude"
import { pipe } from "fp-ts/lib/function"
import { Agora } from "../Agora"

export const LiveSessionPage: React.FC<{}> = ({ }) => {
  const { id: sessionId } = useParams()
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([])

  const [mbLocalVideoTrack, setMbLocalVideoTrack] = useState<O.Option<ICameraVideoTrack>>(O.none)
  const [response, setResponse] = useState<RD.RemoteData<any, LiveSessionsCreationResponse>>(RD.initial)


  React.useEffect(() => {

    if (remoteUsers.length > 0) {
      const firstUser = remoteUsers[0]

      Agora.engine.subscribe(firstUser, "video").then(er => {
        console.log("FIRST USER VIDEO TRACK! ", firstUser.videoTrack)
        setTimeout(() => {
          firstUser.videoTrack?.play("remoteVideo");
        }, 1000);
      })
    }
  }, [remoteUsers])

  React.useEffect(() => {

    async function join() {
      const er = await Api.LiveSessions.joinLiveSession(sessionId!)()
      console.log("RESULT OF JOINING LIVE SESSION! ", er)

      if (E.isRight(er)) {
        const { uid, appId, channelName, channelToken, sessionId } = er.right.data
        setResponse(RD.success(er.right.data))
        console.log("DATA! ", er.right.data)
        await Agora.engine.setClientRole('host')
        await Agora.engine.join(appId, channelName, channelToken, uid)

        const localVideoTrack = await AgoraRTC.createCameraVideoTrack()
        const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()

        setRemoteUsers(Agora.engine.remoteUsers)

        await sleep(1000)

        setMbLocalVideoTrack(O.some(localVideoTrack))

        await sleep(3000)

        localVideoTrack.play("localVideo")

        await sleep(3000)

        const publishResults = await Agora.engine.publish([localVideoTrack, localAudioTrack])
        console.log("PUBLISH RESULTS! ", publishResults)

      }
    }

    join().then().catch(er => console.log("ERROR! ", er))
  }, [])

  return pipe(
    mbLocalVideoTrack,
    O.fold(
      () => (
        <div className="w-screen h-screen">
        </div>
      ),
      (localVideoTrack) => (
        <div className="w-screen h-screen">
          <div>
            {sessionId}
          </div>
          <div>
            {JSON.stringify(response)}
          </div>
          <div>
            {Agora.engine.uid}
          </div>

          <div id="localVideo" style={{ width: '300px', height: '300px', border: '1px solid blue' }} />
          <div id="remoteVideo" style={{ width: '300px', height: '300px', border: '1px solid black' }} />

          <div className="btn" onClick={() => {
            localVideoTrack.close()
          }}>
            Leave
          </div>
        </div>
      )
    )
  )

}