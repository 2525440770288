import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Api } from "../../Services/Backend/Api";
import { OnboardFormContainer, OnboardPageContainer } from "./OnboardFormContainer"

type ProfileForm = {
  firstName: string
  lastName: string
}

export const OnboardProfilePage: React.FC<{}> = ({ }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<ProfileForm>();
  const navigate = useNavigate()

  const onSubmit = (data: ProfileForm) => {
    console.log("DATA ! ", data)
    Api.Me.updateProfile(data)().then(r => {
      console.log("UPDATE RESULT! ", r)
      navigate('/onboard/join_existing_groups')
    })

  }

  return (
    <OnboardFormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className="form-control w-full max-w-xs mt-8">
        <label className="label">
          <span className="label-text font-bold">First Name</span>
        </label>
        <input
          type="text" className="input input-bordered w-full max-w-xs"
          {...register('firstName', { required: true })}
        />
      </div>
      {errors.firstName && <span>This field is required</span>}
      <div className="form-control w-full max-w-xs mt-8">
        <label className="label">
          <span className="label-text font-bold">Last Name</span>
        </label>
        <input
          type="text" className="input input-bordered w-full max-w-xs"
          {...register('lastName', { required: true })}
        />
      </div>
      {errors.lastName && <span>This field is required</span>}
      <div className='mt-16'>
        <input type='submit' className='btn btn-accent' value='next' />
      </div>
    </OnboardFormContainer>
  )
}